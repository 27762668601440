import store from "../../../store/index";
import {
  CHATS_LOADING,
  CHATS_ERROR,
  CHATS_LOADED,
  SINGLE_CHAT_ERROR,
  SINGLE_CHAT_LOADED,
  INSERT_NEW_MESSAGE,
  UPDATE_CHATS_WHEN_MESSAGE_GOT,
  UPDATE_CHAT_UNSEEN_MESSAGE_COUNT,
  SINGLE_CHAT_LOADING,
  EMPTY_SINGLE_CHAT,
} from "../types";
import { socket } from "../../../connection/socket";
import {
  getChatFromLocalStorage,
  storeChatsLocalStorageInFirstTime,
} from "../../../storage/chats";
import { fetchSingleChat, getChats } from "../../../services/chat";
import { notifySound } from "../../../shared/audios";

export const insertNewMessageAction = (message, native = false) => {
  let state = store?.getState();

  const chatId = parseInt(message?.chatId);
  const willDispatch =
    parseInt(state?.singleChat?.chat?.id) === chatId ||
    parseInt(state?.singleChat?.chat?.otherUser?.id) ===
      parseInt(message?.senderId) ||
    parseInt(state?.singleChat?.chat?.otherUser?.id) ===
      parseInt(message?.receiverId);

  if (willDispatch) {
    store.dispatch({ type: INSERT_NEW_MESSAGE, payload: message });
  }

  message.chatId = chatId;
  store.dispatch({
    type: UPDATE_CHATS_WHEN_MESSAGE_GOT,
    payload: { message, inc: !willDispatch },
  });

  if (!native) {
    socket.sendMessage(willDispatch ? "message_read" : "message_received", {
      messageUuid: message?.uuid,
      messageId: message?.id,
    });
  }

  if (!willDispatch) {
    let chat = state?.chats?.data?.find((c) => c?.id === chatId);
    if (chat && !chat?.isMuted) notifySound();
  }
  return;
};

export const getChatsAction =
  ({ page, limit, type = "all", firstTime }) =>
  (dispatch) => {
    dispatch({ type: CHATS_LOADING });
    return getChats({ page, limit, type })
      .then((allData) => {
        firstTime &&
          storeChatsLocalStorageInFirstTime({
            chats: allData.data,
            ...allData,
          });
        dispatch({
          type: CHATS_LOADED,
          payload: { ...allData, reset: firstTime },
        });
      })
      .catch((e) => {
        console.error(e);
        dispatch({ type: CHATS_ERROR, payload: "error" });
      });
  };

export const destroySingleChatAction = () => (dispatch) => {
  dispatch({ type: EMPTY_SINGLE_CHAT });
};

export const getMessagesAction =
  ({ page, limit, chatId, getUsersChat, loadMore, callback }) =>
  async (dispatch) => {
    if (!loadMore) dispatch({ type: SINGLE_CHAT_LOADING });

    if (!loadMore) {
      let fromLo = null;
      fromLo = await getChatFromLocalStorage(chatId);
      if (fromLo?.chat?.fetched) {
        dispatch({
          type: UPDATE_CHAT_UNSEEN_MESSAGE_COUNT,
          payload: { count: 0, chatId: parseInt(chatId) },
        });

        dispatch({
          type: SINGLE_CHAT_LOADED,
          payload: {
            loadMore,
            messages: {
              thisPage: fromLo?.messages.meta.thisPage,
              allPages: fromLo?.messages.meta.allPages,
              count: fromLo?.messages.meta.count,
              data: fromLo?.messages?.data,
            },
            chat: fromLo.chat,
            hasMore: fromLo.hasMore,
          },
        });
        if (callback) callback();
        return;
      }
    }

    return fetchSingleChat(
      { page, limit, chatId, getUsersChat, loadMore },
      !loadMore
    )
      .then(
        ({ data, thisPage, allPages, chat, hasMore, count, otherChats }) => {
          thisPage === 1 &&
            dispatch({
              type: UPDATE_CHAT_UNSEEN_MESSAGE_COUNT,
              payload: { count: 0, chatId: parseInt(chatId) },
            });

          dispatch({
            type: SINGLE_CHAT_LOADED,
            payload: {
              messages: { data, thisPage, allPages, count },
              chat,
              otherChats,
              loadMore,
              hasMore,
            },
          });
          if (callback) callback();
        }
      )
      .catch((e) => {
        console.error(e);
        if (callback) callback(e);
        dispatch({ type: SINGLE_CHAT_ERROR, payload: "error" });
      });
  };
